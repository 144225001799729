import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Canvas Creations
			</title>
			<meta name={"description"} content={"Уявляйте. В чорнило. Надихайте. Завітайте до Canvas Creations, де ваші історії намальовані чорнилом."} />
			<meta property={"og:title"} content={"Canvas Creations"} />
			<meta property={"og:description"} content={"Уявляйте. В чорнило. Надихайте. Завітайте до Canvas Creations, де ваші історії намальовані чорнилом."} />
			<meta property={"og:image"} content={"https://kexonovel.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://kexonovel.com/img/heart.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://kexonovel.com/img/heart.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://kexonovel.com/img/heart.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://kexonovel.com/img/heart.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://kexonovel.com/img/heart.png"} />
			<meta name={"msapplication-TileImage"} content={"https://kexonovel.com/img/heart.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="100%" />
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="--color-darkL2"
				md-padding="48px 40px 48px 40px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline2"
					lg-text-align="left"
					sm-font="--headline3"
				>
					Про нас
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="--base"
					lg-text-align="left"
					md-margin="0px 0px 28px 0px"
				>
					Тату салон Canvas Creations був заснований за принципом перетворення особистих історій на яскраве, постійне мистецтво. Наш шлях почався з простої місії: підняти татуювання до рівня мистецтва, яке поважає індивідуальність кожного клієнта та відзначає різноманітність його натхнення. За ці роки ми створили простір, де процвітає творчість, а бачення клієнтів реалізуються з максимальною ретельністю та артистизмом.
				</Text>
			</Box>
			<Box
				display="flex"
				width="60%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				lg-order="-1"
			>
				<Image
					src="https://kexonovel.com/img/2.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					max-height="600px"
					width="100%"
					object-position="0% 70%"
					sm-max-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-9">
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="1fr"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="column"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="30%"
						md-width="100%"
					>
						Наші художники
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="70%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Серцем Canvas Creations є наші художники, кожен з яких привносить у студію свій унікальний стиль та досвід. Вони не просто майстри тату - вони піонери сучасного тату-мистецтва, які постійно розширюють межі дизайну та техніки. Наша команда прагне до особистісного зростання та мистецької досконалості, гарантуючи, що кожна робота, яку вони виконують, є як особистим, так і мистецьким проривом.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="30%"
						md-width="100%"
					>
						Чому Canvas Creations?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="70%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Мистецтво на замовлення: Кожне татуювання створюється індивідуально, щоб ідеально доповнити ваше бачення та тіло.
						<br/><br/>
Передова техніка: Ми використовуємо новітні технології татуювання та художні техніки, щоб забезпечити точність і якість.
						<br/><br/>
Художня цілісність: Наші майстри присвячують себе етичному та творчому татуюванню, зосереджуючись на вашому комфорті та задоволенні.

					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="30%"
						md-width="100%"
					>
						Приєднуйтесь до нашої команди
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="70%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Ваша історія унікальна, і ваше татуювання має бути таким же. У Canvas Creations ми запрошуємо вас стати частиною творчої подорожі, яка вшанує вашу особисту історію за допомогою вишуканих, індивідуальних чорнил. Зв'яжіться з нами і дізнайтеся, як ваші ідеї можуть втілитися в життя під майстерними руками наших художників.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="30%"
						md-width="100%"
					>
						Зв'язатися з нами
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="70%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Завітайте до Canvas Creations і вийдіть звідти з шедевром - вашою шкірою, нашим полотном.

					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});